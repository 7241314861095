@import "src/styles/abstracts/colors";;

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: $blue !important;
  border-color: $blue !important;
}

.page-link {
  color:  $blue !important;
}

pagination {
  font-size: 15px;
}
