@import "src/styles/abstracts/colors";;
@import 'breakpoints';


@mixin btn {
  height: 45px;
  border-radius: 6px;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.07);
  font-size: 14px;
  font-weight: bold;
  padding: 0 40px;
  min-width: 125px;
  margin: 0 10px;
}

@include handset() {
  .atr-btn {
    &--blue, &--text,
    &--transparent, &--load-more,
    &--report, &--white {
      font-size: 14px;
      line-height: 18px;
      width: 100%;
    }
  }
}
// .dots-load-more {
//   border-top: 1px solid $gray-light-2;
//   padding: 3rem;

//   &__button {
//     margin: auto;
//     display: block;
//     width: 17rem;
//     font-size: 1.6rem;
//   }
// }

