@use 'node_modules/@rds/core/styles/abstracts/functions' as functions;
@use 'node_modules/@rds/core/styles/abstracts/mixins' as mixins;

$transition-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;

.full-editor {
  .ck-editor__editable {
    .announcement-box {
      display: flex;
      flex-wrap: wrap;

      .custom-figure {
        height: 348px;
        padding: 12px 0;

        img {
          flex: 1;
          width: 324px;
          height: 324px;
          object-fit: cover;
        }
      }

      .announcement-box-description {
        flex: 2;
        background: var(--rds-color-400-neutral-4) !important;;
      }

      &[data-image-side="right"] {
        .custom-figure {
          order: 2;
          transform: translate(-48px, 48px);
        }

        .announcement-box-description {
          order: 1;
          padding: 24px 72px 24px 24px;
        }
      }

      &[data-image-side="left"] {
        .custom-figure {
          order: 1;
          transform: translate(48px, 48px);
        }

        .announcement-box-description {
          order: 2;
          padding: 24px 24px 24px 72px;
        }
      }
    }

    .text-box {
      display: flex;


    }

    .text-box[custom-type="block"] {
      display: block;
      padding: 60px;
    }

    .text-box-text {
      padding: 12px 0;
      flex: 1;

      &:not(:last-child):not(:first-child) {
        margin: 0 6px;
      }

      &:first-child {
        margin-right: 6px;
      }

      &:last-child {
        margin-left: 6px;
      }


    }


    .image-and-quote-box {
      position: relative;
      display: flex;
      width: 100%;

      .image-and-quote-box-text {


        blockquote {
          padding-left: unset;
          padding-right: unset;
        }
      }

      &[data-image-side="left"] {
        .image-and-quote-box-text {
          order: 2;
          margin-left: 6px;
        }

        .custom-figure {
          order: 1;
          margin-right: 6px;
        }
      }

      &[data-image-side="right"] {
        .image-and-quote-box-text {
          order: 1;
          margin-right: 6px;
        }

        .custom-figure {
          order: 2;
          margin-left: 6px;
        }
      }

      &[data-image-width="33%"] {
        .custom-figure {
          width: calc(100% / 3);
        }

        .image-and-quote-box-text {
          width: calc(100% / 3 * 2);
        }
      }

      &[data-image-width="50%"] {
        .custom-figure {
          width: 50%;
        }

        .image-and-quote-box-text {
          width: 50%;
        }

      }

      &[data-image-width="66%"] {
        .custom-figure {
          width: calc(100% / 3 * 2);
        }

        .image-and-quote-box-text {
          width: calc(100% / 3);
        }
      }

      .custom-figure {
        height: auto;

        img {
          width: 100%;
        }
      }
    }

    .image-and-text-box {
      position: relative;
      display: flex;
      width: 100%;


      &[data-image-side="left"] {
        .image-and-text-box-text {
          order: 2;
          margin-left: 6px;
        }

        .custom-figure {
          order: 1;
          margin-right: 6px;
        }
      }

      &[data-image-side="right"] {
        .image-and-text-box-text {
          order: 1;
          margin-right: 6px;
        }

        .custom-figure {
          order: 2;
          margin-left: 6px;
        }
      }

      &[data-image-width="33%"] {
        .custom-figure {
          width: calc(100% / 3);
        }

        .image-and-text-box-text {
          width: calc(100% / 3 * 2);
        }
      }

      &[data-image-width="50%"] {
        .custom-figure {
          width: 50%;
        }

        .image-and-text-box-text {
          width: 50%;
        }

      }

      &[data-image-width="66%"] {
        .custom-figure {
          width: calc(100% / 3 * 2);
        }

        .image-and-text-box-text {
          width: calc(100% / 3);
        }
      }

      .custom-figure {
        height: auto;

        img {
          width: 100%;
        }
      }
    }

    .movie-and-text-box {
      display: flex;
      width: 100%;

      .movie-and-text-media {
        flex: 1;

        > .media {
          margin: 0;
          padding: 0;
          min-height: 100%;
          background-image: url('/assets/images/media-placeholder.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

          iframe {
            width: 480px;
            height: 360px;
          }
        }
      }

      .movie-and-text-media.ck-widget .ck-widget__type-around__button {
        display: none;
      }

      .movie-and-text-text {
        flex: 1;
      }

      &[data-side="left"] {
        > .movie-and-text-media {
          order: 1;
          margin-right: 6px;
        }

        .movie-and-text-text {
          order: 2;
          margin-left: 6px;
        }
      }

      &[data-side="right"] {
        .movie-and-text-media {
          order: 2;
          margin-left: 6px;
        }

        .movie-and-text-text {
          order: 1;
          margin-right: 6px;
        }
      }
    }

    .images-and-text-box {
      display: flex;
      position: relative;
      width: 100%;

      .images-and-text-text {
        flex: 1;
        width: 100%;
        margin: 0 12px;
      }

      .custom-figure {
        flex: 1;
        width: 100%;

        > .custom-image {
          width: 100%;
        }

      }
    }

    .images-box {
      display: flex;

      > .custom-figure {
        width: 100%;
        height: 100%;
        flex: 1;

        > .custom-image {
          width: 100%;
          margin: 0;
        }
      }

      > .custom-figure:not(:last-child):not(:first-child) {
        margin: 0 6px;
      }

      > .custom-figure:first-child {
        margin-right: 6px;
      }

      > .custom-figure:last-child {
        margin-left: 6px;
      }
    }
  }

  .ck-custom-fieldset {
    background: var(--rds-color-white) !important;
    display: flex;
    border: none !important;
    padding: 4px 6px !important;
    vertical-align: middle;

    > label {
      flex: 1;
      min-width: 110px;
      line-height: 36px !important;
    }

    .ck-button-dropdown {
      justify-content: flex-end;

      height: 36px !important;
      width: 36px !important;
      padding: 0 !important;

      .ck-icon {
        height: unset !important;
        width: unset !important;
      }
    }

    > .ck.ck-icon.ck-icon_inherit-color :not([fill]) {
      fill: none !important;
    }
  }

  .ck-media-form, .ck-link-form {
    //background-color: var(--rds-color-white);
  }

  .ck-custom-fieldset-with-form {
    background: var(--rds-color-white) !important;

    form {
      display: flex;
      align-items: flex-start;
    }
  }

  .ck-custom-fieldset-toolbar {
    background: var(--rds-color-white) !important;

    .ck-toolbar__items {
      background: var(--rds-color-white) !important;

      > .ck-button {
        background: var(--rds-color-white) !important;
        height: 28px !important;
        width: 28px !important;
        padding: 0 !important;

        > svg {
          height: 100% !important;
          width: 100% !important;
        }

      }
    }
  }

  .ck-dropdown__panel {
    background: var(--rds-color-white) !important;
  }

  .ck-list__item::before {
    display: none;
  }

  .ck-media-form, .ck-link-form {
    background-color: var(--rds-color-white);
  }
}

:host::ng-deep {
  .ck.ck-list__item .ck-button.ck-on {
    background: var(--ck-color-list-button-on-background) !important;
    color: var(--ck-color-list-button-on-text) !important;
  }
}
