@import "src/styles/abstracts/colors";;

.mobile-checkbox {
    display: block;
    position: relative;
    padding-right: 25px;
    margin-bottom: 5px;
    font-size: 12px;
    padding-top: 2px;
    cursor: pointer;
}
    .mobile-checkbox input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
.checkbox_indicator {
    position: absolute;
    top: 2px;
    right: 0;
    height: 17px;
    width: 17px;
    background: transparent;
    border: 2px solid $gray-light-4;
    border-radius: 0px;
}
.mobile-checkbox:hover input ~ .checkbox_indicator,
.mobile-checkbox input:focus ~ .checkbox_indicator {
    background: transparent;
}

.mobile-checkbox input:checked ~ .checkbox_indicator {
    background: transparen;
}
.mobile-checkbox:hover input:not([disabled]):checked ~ .checkbox_indicator,
.mobile-checkbox input:checked:focus ~ .checkbox_indicator {
    background: transparent;
}
.mobile-checkbox input:disabled ~ .checkbox_indicator {
    background: $gray-light-7;
    opacity: 0.6;
    pointer-events: none;
}
.checkbox_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.mobile-checkbox input:checked ~ .checkbox_indicator:after {
    display: block;
}
.mobile-checkbox .checkbox_indicator:after {
    left: 4px;
    top: 0px;
    width: 4px;
    height: 8px;
    border: solid $gray;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.mobile-checkbox input:disabled ~ .checkbox_indicator:after {
    border-color: $gray-load-more;
}
