.ck-with-blocks {
  .ck-content {
    blockquote {
      padding-left: unset;
      padding-right: unset;
      border-left: none;
    }
  }

  .iframe-container {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    padding-bottom: 56.2493%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .bg-class {
    position: relative;

    &__content {
      padding-top: 16px;
      padding-bottom: 12px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 12px);
        background: inherit;
        z-index: 0;
      }

      > * {
        position: relative;
        z-index: 1;
      }
    }
  }


  @media screen and (min-width: 560px) {
    .images-box {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;


      .custom-figure:first-child:nth-last-child(1) {
        /* -or- li:only-child { */
        width: 100%;
      }

      /* two items */
      .custom-figure:first-child:nth-last-child(2),
      .custom-figure:first-child:nth-last-child(2) ~ .custom-figure {
        width: calc(50% - 6px);
      }

      /* three items */
      .custom-figure:first-child:nth-last-child(3),
      .custom-figure:first-child:nth-last-child(3) ~ .custom-figure {
        width: 100%;
      }

      /* four items */
      .custom-figure:first-child:nth-last-child(4),
      .custom-figure:first-child:nth-last-child(4) ~ .custom-figure {
        width: calc(50% - 6px);
      }
    }

    .image-and-text-box {
      position: relative;
      display: flex;
      gap: 12px;
      width: 100%;

      &[data-image-side="left"] {
        .image-and-text-box-text {
          order: 2;
          flex: 1;
        }

        .custom-figure {
          order: 1;
          flex: 1;
        }
      }

      &[data-image-side="right"] {
        .image-and-text-box-text {
          order: 1;
          flex: 1;
        }

        .custom-figure {
          order: 2;
          flex: 1;
        }
      }
    }
    .image-and-quote-box {
      position: relative;
      display: flex;
      gap: 12px;
      width: 100%;

      .image-and-quote-box-text {
        padding-left: 12px;

        blockquote {
          padding-left: unset;
          padding-right: unset;
          border-left: none;
        }
      }

      &[data-image-side="left"] {
        .image-and-quote-box-text {
          order: 2;

        }

        .custom-figure {
          order: 1;
          flex: 1;
        }
      }

      &[data-image-side="right"] {
        .image-and-quote-box-text {
          order: 1;
        }

        .custom-figure {
          order: 2;
          flex: 1;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .custom-figure {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .announcement-box {
      transform: translateY(-60px);

      .announcement-box-description {
        background: var(--rds-color-400-neutral-4) !important;
        padding: 60px 24px 24px 24px;
      }

      .custom-figure {
        height: 324px;

        > img {
          width: 60%;
        }
      }

      &[data-image-side="left"] {
        .custom-figure {
          transform: translateY(60px);
        }
      }

      &[data-image-side="right"] {
        .custom-figure {
          transform: translateY(60px);

          > img {
            position: absolute;
            right: 0;
            width: 60%;
          }
        }
      }
    }
    .text-box[custom-type="block"] {
      padding: 20px;
    }
    .media {
      position: relative;
      min-height: 200px;

      .empty-oembed {
        background-image: url(/assets/images/media-placeholder.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        min-height: 200px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .announcement-box {
      display: flex;

      .custom-figure {
        height: 348px;
        padding: 12px 0;

        img {
          flex: 1;
          width: 240px;
          height: 240px;
          object-fit: cover;
        }
      }

      .announcement-box-description {
        flex: 2;
        background: var(--rds-color-400-neutral-4) !important;;
      }

      &[data-image-side="right"] {
        .custom-figure {
          order: 2;
          transform: translate(-36px, 36px);
        }

        .announcement-box-description {
          order: 1;
          padding: 24px 60px 24px 24px;
        }
      }

      &[data-image-side="left"] {
        .custom-figure {
          order: 1;
          transform: translate(36px, 36px);
        }

        .announcement-box-description {
          order: 2;
          padding: 24px 24px 24px 60px;
        }
      }
    }

    .text-box[custom-type="double"], .text-box[custom-type="triple"] {
      display: flex;
      gap: 12px;
    }

    .text-box[custom-type="single"] {
      display: block;
    }

    .text-box[custom-type="block"] {
      display: block;
      padding: 60px;
    }

    .text-box-text {
      padding: 12px 0;
      flex: 1;
    }

    .image-and-text-box {
      position: relative;
      min-height: inherit;
      display: inline-block;

      .custom-figure {
        height: auto;
      }
    }

    .image-and-quote-box {
      position: relative;
      display: flex;
      gap: 12px;
      width: 100%;

      .image-and-quote-box-text {
        padding-left: 12px;

        blockquote {
          padding-left: unset;
          padding-right: unset;
          border-left: none;
        }
      }

      &[data-image-side="left"] {
        .image-and-quote-box-text {
          order: 2
        }

        .custom-figure {
          order: 1
        }
      }

      &[data-image-side="right"] {
        .image-and-quote-box-text {
          order: 1
        }

        .custom-figure {
          order: 2
        }
      }

      &[data-image-width="33%"] {
        .custom-figure {
          width: calc(100% / 3);
        }

        .image-and-quote-box-text {
          width: calc(100% / 3 * 2);
        }
      }

      &[data-image-width="50%"] {
        .custom-figure {
          width: 50%;
        }

        .image-and-quote-box-text {
          width: 50%;
        }

      }

      &[data-image-width="66%"] {
        .custom-figure {
          width: calc(100% / 3 * 2);
        }

        .image-and-quote-box-text {
          width: calc(100% / 3);
        }
      }

      .custom-figure {
        height: auto;

        img {
          width: 100%;
        }
      }
    }

    .image-and-text-box {
      position: relative;
      display: flex;
      gap: 12px;
      width: 100%;

      &[data-image-side="left"] {
        .image-and-text-box-text {
          order: 2
        }

        .custom-figure {
          order: 1
        }
      }

      &[data-image-side="right"] {
        .image-and-text-box-text {
          order: 1
        }

        .custom-figure {
          order: 2
        }
      }

      &[data-image-width="33%"] {
        .custom-figure {
          width: calc(100% / 3);
        }

        .image-and-text-box-text {
          width: calc(100% / 3 * 2);
        }
      }

      &[data-image-width="50%"] {
        .custom-figure {
          width: 50%;
        }

        .image-and-text-box-text {
          width: 50%;
        }

      }

      &[data-image-width="66%"] {
        .custom-figure {
          width: calc(100% / 3 * 2);
        }

        .image-and-text-box-text {
          width: calc(100% / 3);
        }
      }

      .custom-figure {
        height: auto;

        img {
          width: 100%;
        }
      }
    }

    .movie-and-text-box {
      display: flex;
      gap: 12px;
      width: 100%;

      .movie-and-text-media {
        flex: 1;

        > .media {
          margin: 0;
          padding: 0;
          min-height: 100%;
          position: relative;

          .empty-oembed {
            background-image: url(/assets/images/media-placeholder.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }

      .movie-and-text-text {
        flex: 1;
      }

      &[data-side="left"] {
        > .movie-and-text-media {
          order: 1;
        }

        .movie-and-text-text {
          order: 2;
        }
      }

      &[data-side="right"] {
        .movie-and-text-media {
          order: 2
        }

        .movie-and-text-text {
          order: 1;
        }
      }
    }

    .images-and-text-box {
      display: flex;
      gap: 12px;
      position: relative;
      width: 100%;

      .images-and-text-text {
        flex: 1;
        width: 100%;
      }

      .custom-figure {
        flex: 1;
        width: 100%;

        > .custom-image {
          width: 100%;
        }

      }
    }

    .images-box {
      display: flex;
      gap: 12px;

      > .custom-figure {
        width: 100%;
        height: 100%;
        flex: 1;

        > .custom-image {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .announcement-box {
      display: flex;

      .custom-figure {
        padding: 12px 0;

        img {
          flex: 1;
          width: 324px;
          height: 324px;
          object-fit: cover;
        }
      }

      &[data-image-side="right"] {
        .custom-figure {
          order: 2;
          transform: translate(-48px, 48px);
        }
      }

      &[data-image-side="left"] {
        .custom-figure {
          order: 1;
          transform: translate(48px, 48px);
        }
      }
    }
  }
}
