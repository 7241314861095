@import 'breakpoints';
@import "src/styles/abstracts/colors";;

.modal-page-cover {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 !important;
  padding: 0 !important;

  .modal-content {
    background: rgba(255, 255, 255, 0.95);
    height:100%;
    width: 100%;
  }
}

.modal-page-cover-no-transparent {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 !important;
  padding: 0 !important;

  .modal-content {
    background: white;
    height:100%;
    width: 100%;
  }
}

.modal-delete-quick-links {
  border-radius: 6px;
  top: 5% !important;
  max-width: 1179px;
  max-height: 484px;
  margin-bottom: 50px !important;
}

.modal-add-quick-links {
  border-radius: 6px;
  top: 5% !important;
  max-width: 890px;
  margin-bottom: 50px !important;

  .modal-content {
    border-radius: 6px;
  }
}

.modal-standard {
  max-width: 1280px;
  margin: 0 auto;
}

.modal-medium {
  max-width: 848px;
  padding: 64px 54px;
  margin: 0 auto;
}

.modal-small {
  max-width: 586px;
  padding: 64px 40px;
  margin: 0 auto;
}

.modal-with-scroll {
  top: 0;
  min-width: 700px;
  padding: 0;
  margin: 0 auto;

}

.modal-dialog {
  top: 20%;
  margin: 5px auto;
  padding: 0 40px;
}

.modal-reset-quicklinks {
    max-width: 848px;
    padding: 64px 54px;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.modal-with-scroll {
  top: 0;
  min-width: 700px;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  &.wide {
    min-width: 1260px;
  }
  .modal-content {
    max-height: calc(100% - 40px);
    margin-top: 40px;
  }
}

.modal-crop-image {
  top: 10%;
  min-width: 1260px;
}

.modal-change-newsletter {
  min-width: 1000px;
}

.modal-overlay-right {
  top: 0;
  margin: 0px 0px 0px auto !important;
  padding: 0px;
  max-width: 640px;
  .modal-content {
    min-height: 100vh;
    border: none !important;
  }
}

.modal-top {
  top: 8%;
}

.modal-mobile {
  top: 16px;
  bottom: 40px;
  margin: 0 -3px 0 -3px;
  padding: 0;
  width:calc(100% + 6px);
  max-width: unset;
}

.modal-mobile-padding {
  top: 16px;
  bottom: 40px;
  padding: 0;
  width: 90%;
  max-width: unset;
}

.modal-open {
  .main.mobile {
    filter: blur(3px);
  }
}

.modal {
  @include handset() {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  }
}

.modal-onboarding-top-stick,
.modal-onboarding-guide,
.modal-onboarding-user-profile,
.modal-onboarding-home,
.modal-onboarding-quicklinks {
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  max-width: unset;
  opacity: 0.95;
  background-color: $white;
  .modal-content {
    border: none;
    height: 100%;
  }
}

.modal-onboarding-guide {
  opacity: 1;
  bottom: 0;
  background-color: transparent;
  .modal-content {
    background-color: transparent;
  }
}

.modal-onboarding-user-profile {
  top: 0;
  height: 353px;
  border: 1px solid $onboarding-white-2;
}

.modal-onboarding-home {
  top: 160px;
  height: 100%;
  border: none;
}

.modal-onboarding-quicklinks {
  bottom: 0;
  top: unset;
  border: 1px solid $onboarding-white-2;
}

.modal-share {
  max-width: 740px;
  padding: 0;
}

@include handset() {
  .modal-onboarding-user-profile, .modal-onboarding-home, .modal-onboarding-quicklinks {
    top: 46px;
    bottom: 0;
    height: 100%;
    border: none;
  }
}

.modal-backdrop {
  z-index: 990;
}

.modal {
  z-index: 995;
}
