/* You can add global styles to this file, and also import other style files */
@use "@rds/angular-components/styles/angular-components" as rds;
@use 'node_modules/@rds/core/styles/abstracts/functions' as functions;
@use 'node_modules/@rds/core/styles/abstracts/mixins' as mixins;

@import 'base/animations';
@import 'base/reset';
@import 'base/typography';
@import 'base/newsletter-typography';
@import 'base/roche-fonts';

@import 'components/buttons';
@import 'components/modals';
@import 'components/search_results_section';
@import 'components/pagination';
@import 'components/datepicker';
@import 'components/mobile_checkbox';
@import 'components/tooltip';
@import 'components/tables';
@import 'components/menu';
@import 'components/material';
@import 'components/ck-content-blocks';
@import 'components/ck-content-form-blocks';
@import 'components/ck-content-preview';
@import 'layout/standard';

@import 'node_modules/angular-calendar/scss/angular-calendar';

@include rds.rds-angular-components();


@import 'base/animations';
@import 'base/reset';
@import 'base/typography';
@import 'base/roche-fonts';

@import 'components/buttons';
@import 'components/modals';
@import 'components/search_results_section';
@import 'components/pagination';
@import 'components/datepicker';
@import 'components/mobile_checkbox';
@import 'components/tooltip';
@import 'components/tables';
@import 'components/menu';
@import 'components/material';
@import 'components/ck-content-blocks';
@import 'components/ck-content-form-blocks';
@import 'components/ck-content-preview';
@import 'layout/standard';

@import 'node_modules/angular-calendar/scss/angular-calendar';

@include rds.rds-angular-components();

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  font-family: RocheSans;
  height: 100%;

  .container-center {
    position: absolute;
    left: 50%;
    top: 45%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 750px !important;
  }
}

body {
  height: 100%;

  // * {
  //   &:focus-within {
  //     outline-color: transparent;
  //   }
  // }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.table-text-overflow {
  max-width: 80px !important;
  min-width: 80px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.break-line-tooltip {
  text-align: center;
  white-space: pre-line;
}

.disabled-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.hidden-scroll {
  position: fixed;
  overflow-y: hidden;
  width: 100%;
}

.block-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

// Temporary helpers classes RH2.0
.text-uppercase {
  text-transform: uppercase;
}

.text-nodecor {
  text-decoration: none;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-low-contrast {
  color: #706B69;

  svg {
    fill: #706B69;
  }
}

.text-high-contrast {
  color: #1E1E1E;
}

.gcs-menu {
  max-width: min-content !important;
}

.app-menu {
  min-width: 300px !important;
}

.menu-tree__node--icon {
  display: flex;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

// .rds-menu-item {
//   font-size: 16px !important;
//   font-weight: 400 !important;
//   line-height: 36px !important;
//   text-transform: none !important;
//   letter-spacing: 0.2px !important;
//   font-family: "RocheSans-Regular" !important;
//   -webkit-font-smoothing: antialiased !important;
//   -moz-osx-font-smoothing: grayscale !important;
// }

rds-icon > svg {
  vertical-align: baseline;
}

.rds-chips {
  overflow: hidden;
  padding-bottom: 4px;

  &.nowrap {
    flex-wrap: nowrap;
  }

  &__chip {
    &:not(.notruncate) {
      .rds-chips-wrapper {
        max-width: 100%;
      }

      .rds-chip-action {
        overflow: hidden;
      }
    }

    min-width: 0;
  }
}

b,
strong {
  font-weight: bold !important;
}

.ck-custom-toolbar-button {
  > span {
    width: auto !important;
  }

}

.button-with-background-color.ck-on {
  background: var(--ck-color-list-button-on-background) !important;
}

// .ck-content {
//   li > *:not(ul):not(ol) {
//     display: inline-block;
//   }
// }

.ck-custom-fieldset {
  display: flex;
  border: none !important;
  padding: 4px 6px !important;
  vertical-align: middle;

  > label {
    flex: 1;
    min-width: 110px;
    line-height: 36px !important;
  }

  .ck-button-dropdown {
    justify-content: flex-end;

    height: 36px !important;
    width: 36px !important;
    padding: 0 !important;

    .ck-icon {
      height: unset !important;
      width: unset !important;
    }
  }

  > .ck.ck-icon.ck-icon_inherit-color :not([fill]) {
    fill: none !important;
  }
}

.ck-custom-fieldset-with-form {
  form {
    display: flex;
    align-items: flex-start;
  }
}

.ck-custom-fieldset-toolbar {
  .ck-toolbar__items {
    > .ck-button {
      height: 28px !important;
      width: 28px !important;
      padding: 0 !important;

      > svg {
        height: 100% !important;
        width: 100% !important;
      }

    }
  }
}

.ck-content ul {
  list-style-type: none !important;
}

.ck-content blockquote {
  position: relative;
  font-style: italic;
  overflow: visible;
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// overrides after migrating NB
.overflowing-badge {
  overflow: hidden;

  .rds-badge--container {
    width: 100%;

    > .rds-badge {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 20px;
    }
  }
}

.rds-control-counter {
  display: none;
}

.rds-drawer {
  position: fixed !important;

  &.rds-drawer-m {
    width: min(512px, 90vw) !important;
  }

  .rds-drawer-body {
    overflow-y: scroll;
  }
}

.tooltip-list {
  li::marker {
    color: white;
  }
}

.ellipsis-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.rds-form-field__subscript {
  display: flex;
  flex: 1;

  .right {
    margin-left: auto;
  }
}

.approval-details-popover {
  display: block;
  width: 400px;

  .rds-popover-paragraph {
    display: inherit;
  }
}

.roles-helper-popover, .sitemap-helper-popover {
  display: block;
  width: 400px;

  .rds-popover-paragraph {
    display: inherit;
  }
}

.roles-helper-popover {
  rds-switch {
    width: 100%;

    .rds-switch-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: functions.rds-spacing(4);
    }
  }

  .ui-overline-s {
    rds-icon {
      width: 16px;
      height: 16px;
    }
  }
}

.sitemap-helper-popover {
  .editing {
    border: 1px solid functions.rds-color('stroke-enabled');
    padding: 7px 3px 3px 11px;

    &:hover:not(:focus-within) {
      border-color: functions.rds-color('stroke-hover')
    }

    &:focus-within {
      border-color: transparent;
      outline: 2px solid functions.rds-color('600-roche-blue');
      outline-offset: -2px;
    }
  }

  .viewing {
    border: 1px solid transparent;
    padding: 7px 3px 3px 11px;
  }

  .empty {
    ::before {
      content: 'Type something...';
      color: functions.rds-color('disabled')
    }
  }
}

.text-ellipsis {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-wrap {
  overflow-wrap: anywhere;
}

.row-centered {
  align-items: center;
}

.row-scrollable {
  overflow-y: auto;
  margin-right: 0;
  margin-left: 0;
}

.offset-auto {
  margin-left: auto;
}

.offset-both {
  margin-left: auto;
  margin-right: auto;
}

.row-nowrap {
  flex-wrap: nowrap;
  overflow: hidden;
  min-width: 0;

  .col, [class^='col-'] {
    overflow: hidden;
  }

  .col-squeez {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

.overlay-dialog {
  background-color: red;

  rds-dialog {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
  }
}

.text-icon {
  vertical-align: bottom;
}

rds-radio-group {
  display: flex;

  &:not(.inline) {
    flex-direction: column;
  }

  width: max-content;
  gap: functions.rds-spacing(5);
  &[size='s'] {
    gap: functions.rds-spacing(3);
  }
  &[size='m'] {
    gap: functions.rds-spacing(4);
  }
  &[size='l'] {
    gap: functions.rds-spacing(5);
  }

  
  .rds-radio-label {
    white-space: unset;
  }
}

.bg-neutral-dark-colour {
  background-color: var(--rds-color-400-neutral-4) !important;
}

.bg-neutral-medium-colour {
  background-color: var(--rds-color-200-neutral-2) !important;
}

.bg-neutral-light-colour {
  background-color: var(--rds-color-100-neutral-1) !important;
}

.neutral-background {
  background: functions.rds-color('100-neutral-1');
}

p, span {
  overflow-wrap: break-word;
}

.custom-figure {
  display: block;
  text-align: center;
  word-break: break-word;

  > figcaption {
    margin-top: -12px;
    background-color: var(--ck-color-image-caption-background);
    caption-side: bottom;
    color: var(--ck-color-image-caption-text);
    font-size: .75em;
    outline-offset: -1px;
    padding: 0.6em;
    word-break: break-word;
  }
}

ul, ol {
  list-style-position: outside !important;
  margin-left: 1.5em;
}

.bg-transparent:not(:hover) {
  background: transparent !important;
}

.ck-list__item::before {
  display: none;
}

.ck.ck-list__item .ck-custom-button.ck-on {
  background: var(--ck-color-list-button-on-background) !important;
  color: var(--ck-color-list-button-on-text) !important;
}

.rds-menu-content {
  .menu {
    &__selected {
      &--icon {
        order: 1;
        margin-left: auto;
        color: functions.rds-color('bg-informative');
      }

      &--item {
        background-color: functions.rds-color('bg-solid-hover-select');
      }
    }
  }

  .selected-icon {
    order: 1;
    margin-left: auto;
  }

  .color-picker-menu-trigger {
    .rds-list-item-text {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .color-picker-menu-item {
    .rds-list-item-text {
      flex: 1;
    }
  }

  .color-box {
    width: 100%;

    &.square {
      width: 24px;
    }

    height: 24px;
    flex-shrink: 0;
    border: 1px solid functions.rds-color('stroke-enabled');

    &:hover:not(.active) {
      border: 1px solid functions.rds-color('black');
    }

    &.active {
      border: 1px solid functions.rds-color('600-roche-blue');
    }
  }
}

.short-menu {
  min-width: unset !important;

  .rds-menu-item {
    justify-content: center;
    align-items: center;
  }
}

[id^="nl-select-"][id$="-panel"] {
  max-width: 1vw;
}

.text-icon {
  width: 1em;
  height: 1em;
  vertical-align: text-top;
}

@media (min-resolution: 120dpi) {
  html {
    font-size: 80% !important;
  }
}


  .rh-custom-toolbar {
    background: red !important;
  }

  .force-hide {
    display: none !important;
  }

  .rich-select-option {
    .rds-list-item-text {
      flex: 1;
    }
  }

  rds-toggle.rds-toggle--s {
    height: unset;
    .rds-toggle__separator {
      height: 16px !important;
      margin-top: 6px !important;
    }
    .rds-toggle__button {
       font-size: 14px;
       font-weight: 400;
       line-height: 20px;
       text-transform: none;
       letter-spacing: .3px;
       font-family: roche-sans;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
       padding: 3px 11px;
     }
 }
  
.language-count {
  rds-badge {
    top: -10px !important;
    right: -9px !important;
  }
}

.horizontal-skeleton {
  &--wrapper {
    display: flex;
    gap: 6px;
    flex-direction: column;
  }
  display: flex;
  gap: 2px;
  width: 80px;
  &__image {
    width: 32px;
    height: 16px;
    background: functions.rds-color('50-shade-grey');
  }
  &__text {
    flex: 1;
    display: flex;
    gap: 2px;
    flex-direction: column;
    &--title,&--description {
      background: functions.rds-color('50-shade-grey');
    }
    &--title {
      height: 6px;
    }
    &--description {
      height: 8px;
    }
  }
}


.vertical-skeleton {
  &--wrapper {
    display: flex;
    gap: 6px;
    flex-direction: column;
  }
  &--row {
    display: flex;
    gap: 6px;
  }
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 40px;
  &__image {
    width: 40px;
    height: 23px;
    background: functions.rds-color('50-shade-grey');
  }
  &__text {
    flex: 1;
    display: flex;
    gap: 2px;
    flex-direction: column;
    &--title,&--description {
      background: functions.rds-color('50-shade-grey');
    }
    &--title {
      height: 6px;
    }
    &--description {
      height: 8px;
    }
  }
}

.number-select-menu {
  max-height: 400px !important;
  overflow-y: scroll !important;
}

// chart tooltip
ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  @include mixins.rds-elevation(3);
  background: functions.rds-color('white');
  color: functions.rds-color('black');
  padding: functions.rds-spacing(4);
  text-align: left;
  .tooltip-caret.position-top {
    border-top-color: functions.rds-color('white');
    color: functions.rds-color('black');
    box-shadow: none;
  }
  .date-badge {
    .rds-badge {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .time-badge {
    .rds-badge {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;	
    }
  }
}

.rds-autocomplete-option {
  text-align: left;
}