// STANDARD RESPONSIVE BREAKPOINTS

$bp-xlarge:  1440px;
$bp-large:   1260px;
$bp-medium:  768px;
$bp-small:   600px;

@mixin xsmall {
  @media (max-width: 599.99px) {
    @content;
  }
}

@mixin small {
  @media (min-width: 600px) and (max-width: 959.99px) {
    @content;
  }
}

@mixin medium {
  @media (min-width: 960px) and (max-width: 1279.99px) {
    @content;
  }
}

@mixin large {
  @media (min-width: 1280px) and (max-width: 1919.99px) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin handset {
  @media (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin web {
  @media (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin handsetPortrait {
  @media (max-width: 599.99px) and (orientation: portrait) {
    @content;
  }
}

@mixin tabletPortait {
  @media (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait) {
    @content;
  }
}

@mixin webPortrait {
  @media (min-width: 840px) and (orientation: portrait) {
    @content;
  }
}

@mixin handsetLandscape {
  @media (max-width: 959.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin tabletLandscape {
  @media (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin webLandscape {
  @media (min-width: 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}
