@import 'breakpoints';
@import "src/styles/abstracts/colors";;

%heading {
  font-weight: normal;
  color: $color-primary;
}

/*
  Font-faces are included in @rhd/angular/ library
*/

.heading-1 {
  @extend %heading;
  font-size: 40px;
  letter-spacing: 0.4px;
  line-height: 46px;
  font-weight: bold;
  &--new-entry, &--role-assign, &--my-content {
    font-size: 44px;
    letter-spacing: 0.83px;
    line-height: 50px;
    color: $gray;
  }
}

.heading-2 {
  @extend %heading;
  font-size: 28px;
  letter-spacing: 0.24px;
  line-height: 36px;
}

.heading-3 {
  @extend %heading;
  font-size: 20px;
  letter-spacing: 0.20px;
  line-height: 26px;
  &--new-entry {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: $gray;
  }
}

.info-text {
  color: $gray-light-2;
  font-size: 13px;
  line-height: 16px;
  word-spacing: 0.15px;
  &--error {
    @extend.info-text;
    color: $color-error;
  }
}

.no-margin {
  margin: 0;
}

.bold-text {
  font-weight: bold;
}

.text-blue {
  color: $roche-blue;
}
