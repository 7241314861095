.newsletter-typography {
    &--heading-1 {
      font-family: "Noto Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 36px;
    }
    &--heading-2 {
      font-family: "Noto Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.2px;
    }
    &--quotation-s {
      font-family: "Noto Serif";
      font-size: 14px;
      font-style: italic !important;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.2px;
    }
    &--body-s {
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.2px;
    }
    &--body-m {
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.2px;
    }
    &--quotation-m {
      font-family: "Noto Serif";
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.2px;
    }
  }