@import "src/styles/abstracts/colors";;

.theme-blue {
  .bs-datepicker-head {
    background-color: $blue;
  }
  bs-days-calendar-view > bs-calendar-layout > div.bs-datepicker-head,
  .bs-datepicker-body table td span.selected,
  .theme-blue .bs-datepicker-body table td.selected span,
  .theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
  .theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: $blue;
  }
  .bs-datepicker-body table td.week span {
    color: $blue;
  }
  .bs-datepicker-body table td {
    color: $gray-2;
  }
  .bs-datepicker-body table th {
    color: $black;
  }
  .bs-datepicker-body table td span.is-other-month,
  .bs-datepicker-body table td.is-other-month span {
    color: rgba(0, 0, 0, 0.40);
  }
  bs-days-calendar-view > bs-calendar-layout > div.bs-datepicker-head >
    bs-datepicker-navigation-view > button.current > span {
      font-size: 16px;
  }
  bs-years-calendar-view > bs-calendar-layout > div.bs-datepicker-head >
    bs-datepicker-navigation-view > button.current > span {
      font-size: 16px;
  }
  bs-month-calendar-view > bs-calendar-layout > div.bs-datepicker-head >
    bs-datepicker-navigation-view > button.current > span {
      font-size: 16px;
  }
  div.bs-datepicker-container > div.bs-calendar-container > div.bs-media-container >
   bs-month-calendar-view > bs-calendar-layout > div.bs-datepicker-head >
   bs-datepicker-navigation-view {
    display: flex;
    > button:nth-child(2) {
      margin: auto;
    }
  }
}
