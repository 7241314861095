@import "src/styles/abstracts/colors";;
/*
 Get rid of default browser margins and paddings for clean start
*/
*,
*::before:not(.outsourced-body__content),
*::after:not(.outsourced-body__content) {
  margin:   0;
  padding:  0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box; // Get rid of calculating box with borders
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  color: $color-display;
  font-weight: 300;
  line-height: 1.6;
}

label {
  margin-bottom: 0px;
}

a, a:hover {
  text-decoration: none;
  color: $blue;
}

b {
  font-weight: 600;
}
