
// RocheSans
@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'RocheSans';
  src: url('/assets/fonts/roche-sans/RocheSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

// RocheSans Condensed
@font-face {
  font-family: 'RocheSansCondensed';
  src: url('/assets/fonts/roche-sans-condensed/RocheSansCondensed-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'RocheSansCondensed';
  src: url('/assets/fonts/roche-sans-condensed/RocheSansCondensed-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'RocheSansCondensed';
  src: url('/assets/fonts/roche-sans-condensed/RocheSansCondensed-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'RocheSansCondensed';
  src: url('/assets/fonts/roche-sans-condensed/RocheSansCondensed-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

// RocheSerif
@font-face {
  font-family: 'RocheSerif';
  src: url('/assets/fonts/roche-serif/RocheSerif-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'RocheSerif';
  src: url('/assets/fonts/roche-serif/RocheSerif-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'RocheSerif';
  src: url('/assets/fonts/roche-serif/RocheSerif-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'RocheSerif';
  src: url('/assets/fonts/roche-serif/RocheSerif-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'RocheSerif';
  src: url('/assets/fonts/roche-serif/RocheSerif-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'RocheSerif';
  src: url('/assets/fonts/roche-serif/RocheSerif-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}
