@use 'node_modules/@rds/core/styles/abstracts/functions' as functions;
@use 'node_modules/@rds/core/styles/abstracts/mixins' as mixins;
@import "src/styles/abstracts/colors";;
@import 'sizes';
@import 'breakpoints';


%grid-base {
  display: grid;
  grid-template-columns: [page-start] minmax(40px, 1fr) [content-start] minmax(100px, $bp-large)[content-end] minmax(40px, 1fr) [page-end];
}

%grid-mobile {
  @include handset() {
    grid-template-columns: [page-start] minmax(16px, 1fr) [content-start] minmax(100px, $bp-large)[content-end] minmax(16px, 1fr) [page-end];
  }
}

.layout {
//   @extend %grid-base;
//   grid-template-rows: min-content $footer;
  background-color: functions.rds-color('50-shade-grey');
//   position: relative;
}

.layout-newsletter {
  @extend %grid-base;
  grid-template-rows: min-content 100px;
  background-color: $gray-layout;
  position: relative;
}

// .main {
//   grid-column: page-start / page-end;
//   min-height: calc(100vh - #{$footer});
//   @extend %grid-base;
//   @extend %grid-mobile;
// }

// .footer {
//   height: $footer;
//   grid-column: page-start / page-end;
// }

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
