@import "src/styles/abstracts/colors";

.search-result-section {
  margin-top: 40px;
  color: $blue;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $gray;
    margin-bottom: 20px;
  }
  &__redirect {
    margin: 5px 0 0 20px;
    font-size: 14px;

    cursor: pointer;
  }

  &__icon {
    fill: $blue;
    &--sm {
      height: 8px;
      width: 8px;
    }
    &--md {
      height: 18px;
      width: 18px;
    }
  }

  &__event {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding-bottom: 20px;
  }
}
