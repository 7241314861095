@import "src/styles/abstracts/colors";;

.rhd-table {
  background-color: $white;

  min-height: 576px;

  &__loader {
    display: flex;
    width: 100%;
    svg {
      width: 100%;
    }
  }

  &__active-cell {
    color: $roche-blue;
    > span {
      cursor: pointer;
    }
  }

  &__action-column {
    text-align: right;
    padding-right: 0px;
    .button-more {
      margin-right: 16px;
    }

  }

  &__header {
    &--standard {
      background-color: $table-th-bg;
    }
    &--loader {
      th {
        border-bottom-width: 0px !important;
      }
    }
    &--filters, &--delete, &--loader {
      background-color: $white;
    }
    &--delete {
      transition: 0.5s ease-in;
    }
  }

  &__hide {
    display: none;
  }
}

