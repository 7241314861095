@import "src/styles/abstracts/colors";;

.atr-tooltip {
  color: $white;
  font-size: 12px;
  line-height: 24px;
  .tooltip-inner {
    padding: 0 10px;
    background-color: $gray-dark-1;
  }
  &--big {
    width: 200px !important;
  }
  &--no-wrap {
    white-space: nowrap;
  }
  .tooltip-points {
    padding-inline-start: 15px;
    text-align: left;
  }
}
