// STANDARD COLORS
$white-dark: #F8F8F8;
$gray: #666666;
$gray-1: #9B9B9B;
$gray-2: #4A4A4A;
$gray-light-1: #AFAFAF;
$gray-light-2: #B2B2B2;
$gray-light-3: #F2F2F2;
$gray-light-4: #E3E3E3;
$gray-light-5: #999999;
$gray-light-6: #B1B3B3;
$gray-light-7: #E5E5E5;
$gray-layout: #F9F9F9;
$gray-load-more: #686868;
$gray-dark: #667081;
$gray-dark-1: #484848;
$black: #333333;
$black-dark: #000000;
$blue: #0066CC;
$blue-mobile-event: #0065CB;
$blue-light: #2A9FD8;
$red: #D8000C;
$crimson: #dc143c;
$alarm: #E40046;
$bright-purple: #A05EB5;
$beige: #EBEBE4;
$onboarding-white-1: #F5F5F5;
$onboarding-white-2: #D8D8D8;


// PLEASE HERE USE CUSTOM NAMES
$color-display: $gray-2;
$color-primary: $blue;
$color-error: $red;
$color-disabled: $beige;

//Entry statuses colors
$status-green: #00965E;
$status-yellow: #FFC72C;
$status-grey: #D8D8D8;
$status-red: #E40046;


// RHD-COLORS

//Primary colors
$roche-blue: #0066cc;
$white: #ffffff;
$roche-black: #1b1b1b;
$roche-grey: #333333;

//Primary colors shades
$blue-dark: #004d99;
$blue-light-60: #66a3e0;
$blue-light-40: #99c2eb;
$blue-light-20: #cce0f5;
$blue-light-10: #e5effa;
$blue-light-5: #f2f7fc;

$grey-dark: #262626;
$grey-light-80: #666666;
$grey-light-60: #858585;
$grey-light-40: #adadad;
$grey-light-20: #d6d6d6;
$grey-light-10: #eaeaea;
$grey-light-5: #f2f2f2;

//Secondary colors
$roche-cyan: #00e5ef;
$roche-green: #00965e;
$roche-orange: #ed8b00;
$roche-red: #e40046;
$roche-violet: #a05eb5;
$roche-yellow: #ffc72c;

//Secondary color shades
$cyan-dark: #00acb3;
$cyan-light-60: #66EFF5;
$cyan-light-40: #99F5F9;
$cyan-light-20: #CCFAFC;
$cyan-light-5: #F2FEFE;

$green-dark: #006544;
$green-light-60: #66C09E;
$green-light-40: #99CFBD;
$green-light-20: #CCEADF;
$green-light-5: #F2FAF7;

$orange-dark: #C88000;
$orange-light-60: #F4B966;
$orange-light-40: #F8D199;
$orange-light-20: #FBE8CC;
$orange-light-5: #FEF9F2;

$red-dark: #990026;
$red-light-60: #EF6690;
$red-light-40: #EB99AD;
$red-light-20: #FACCDA;
$red-light-5: #FEF2F6;

$violet-dark: #784788;
$violet-light-60: #C69ED3;
$violet-light-40: #D9BFE1;
$violet-light-20: #ECDFF0;
$violet-light-5: #FAF7FB;

$yellow-dark: #D9A421;
$yellow-light-60: #FFDD80;
$yellow-light-40: #FFE7A1;
$yellow-light-20: #FFF4D5;
$yellow-light-5: #FFFCF4;

$border-grey: #D6D6D6;
// table colors

$table-th-bg: #F9F9FA;
$table-cell-brdr: #E0EBF4
